import React from "react";

const Keyword = ({ word }) => {
  return (
    <div className="text-xs text-slate-900 bg-neutral-100 rounded-full px-2 py-1 inline-block mr-1.5">
      <span>{word}</span>
    </div>
  );
};

export default Keyword;
