import React from "react";

import SearchBarHome from "../SearchBarHome";

const Hero = ({ setProperties }) => {
  return (
    <div className="flex flex-col max-w-3xl mt-32">
      <h1 className="text-5xl font-medium text-black mb-4">
        Your property hunting copilot
      </h1>
      <p className="text-lg text-slate-700 max-w-xl mb-12">
        Whether you're new to real estate or a seasoned investor, REA is your
        guide. Our platform simplifies every step, from market insights and
        financing tips to portfolio management.
      </p>
      <SearchBarHome setProperties={setProperties} />
    </div>
  );
};

export default Hero;

// Make confident, informed decisions with real-time alerts, personalised
//         recommendations, and all the tools you need to succeed in real estate.
