import React from "react";

const Prompt = ({ query }) => {
  return (
    <div className="rounded-full bg-gray-200 px-5 py-2.5 inline-block max-w-[50%] sm:max-w-full">
      <p className="text-sm text-black">
        {query ||
          "Can you find me properties in San Francisco with at least 2 beds and 1 bath?"}
      </p>
    </div>
  );
};

export default Prompt;
