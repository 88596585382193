import React from "react";

const PropertySummary = ({ formattedAddress, price, beds, baths, sqft }) => {
  return (
    <div className="flex items-center justify-between my-5">
      <div className="flex flex-col w-full">
        <h3 className="text-gray-400 text-sm">PROPERTY ADDRESS</h3>
        <span className="text-black text-base font-semibold">
          {formattedAddress}
        </span>
      </div>
      <div className="flex w-2/5 justify-between">
        <div className="flex flex-col mr-3">
          <h3 className="text-gray-400 text-sm">PRICE</h3>
          <span className="text-black text-base font-semibold">{price}</span>
        </div>
        <div className="flex flex-col mr-3">
          <h3 className="text-gray-400 text-sm">BEDS</h3>
          <span className="text-black text-base font-semibold">{beds}</span>
        </div>
        <div className="flex flex-col mr-3">
          <h3 className="text-gray-400 text-sm">BATHS</h3>
          <span className="text-black text-base font-semibold">{baths}</span>
        </div>
        <div className="flex flex-col">
          <h3 className="text-gray-400 text-sm">SQFT</h3>
          <span className="text-black text-base font-semibold">{sqft}</span>
        </div>
      </div>
    </div>
  );
};

export default PropertySummary;
