import React from "react";

const LoginButtonMargin = ({ handleLoginModal, handleSignUpModal }) => {
  return (
    <div className="flex flex-col items-center justify-center p-3 h-screen">
      <p className="px-4 text-black text-sm mb-2">
        Log in to view your history and watchlists.
      </p>
      <button
        className="bg-black text-white rounded-full w-full py-2"
        onClick={handleLoginModal}
      >
        Log In
      </button>
      <p
        className="px-4 text-slate-500 text-sm mt-2 cursor-pointer"
        onClick={handleSignUpModal}
      >
        Already have an account?
      </p>
    </div>
  );
};

export default LoginButtonMargin;
