import React, { useState, useRef } from "react";
import { ArrowUp, Loader } from "lucide-react";
import axios from "axios";

const SearchBar = ({ currentCriteria, onUpdateCriteria, setLoadingState }) => {
  const [inputValue, setInputValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  // Handle input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // API call to update criteria based on new input
  const handleSearch = async () => {
    setLoading(true);
    setLoadingState(true);
    inputRef.current.blur();

    try {
      const response = await axios.post(
        "https://analyzeinput2-uvlx53yrmq-uc.a.run.app",
        { input: inputValue, criteria: currentCriteria }
      );

      const { criteria: updatedCriteria, properties } = response.data;
      // Pass inputValue as newQuery for tracking search queries
      onUpdateCriteria(properties, updatedCriteria, inputValue);
    } catch (error) {
      console.error("Error updating criteria:", error);
    } finally {
      setLoading(false);
      setLoadingState(false);
      setInputValue("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const isButtonDisabled = !inputValue;
  const buttonClassName = isButtonDisabled
    ? "bg-neutral-200 cursor-not-allowed"
    : "bg-black";
  const arrowClassName = isButtonDisabled ? "text-zinc-500" : "text-white";

  return (
    <div className="relative rounded-full backdrop-blur-md bg-grey-500/50 p-2 shadow-2xl">
      <div className="relative border rounded-full border-slay-400 w-full ">
        {isButtonDisabled && isHovered && (
          <div className="absolute right-0 -translate-y-full transform mb-4 bg-slate-900 text-white text-xs rounded p-1">
            Property description is empty
          </div>
        )}
        <button className="absolute top-1/2 left-1 transform -translate-y-1/2 rounded-full px-2 py-2 bg-gradient-to-r from-black to-gray-500">
          {loading ? (
            <Loader className="text-white" />
          ) : (
            <Loader className="text-gray-300" />
          )}
        </button>
        <input
          type="text"
          placeholder="What type of property are you looking for?"
          className="w-full pr-4 pl-14 py-3 rounded-full bg-gray-50 text-base"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          ref={inputRef}
        />
        <div
          name="warning-trigger"
          className="absolute right-1 top-1/2 transform -translate-y-1/2"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <button
            className={`rounded-full px-2 py-2 ${buttonClassName}`}
            disabled={isButtonDisabled || loading}
            onClick={handleSearch}
          >
            <ArrowUp
              className={`transition-colors duration-200 ${arrowClassName}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;














