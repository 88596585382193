import React from "react";
import { SquareArrowOutUpRight } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const AvailabilityAndPlan = () => {
  return (
    <div className="flex border-black border border-y-1 border-x-0 mt-3">
      <div className="flex flex-col border border-black border-l-0 border-r-1 border-y-0 p-1 w-1/5">
        <span className="text-sm font-medium mb-0.5">Available from</span>
        <span> 27 October 2024</span>
      </div>
      <div className="flex flex-col p-1 w-1/3 ">
        <div className="flex items-center my-1">
          <ActionButton Text={"View floor plan"} Icon={SquareArrowOutUpRight} />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityAndPlan;
