import React from "react";
import { ChevronDown, X } from "lucide-react";

const SignUpModal = ({ isOpen, onClose }) => {
  //   const submit = useSubmit();

  //   const handleSubmit = (event) => {
  //     event.preventDefault(); // Prevent the default form submission
  //     const formData = new FormData(event.target);
  //     submit(formData); // Submit the form data using useSubmit
  //     onClose(); // Close the modal after submission
  //   };

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 transition-transform duration-300 ease-in-out">
        <div className="fixed inset-0 bg-black/50" onClick={onClose} />
        <div className="fixed left-[50%] top-[50%] bg-white grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border rounded-lg p-4">
          <div className="flex w-full justify-end">
            <button onClick={onClose}>
              {" "}
              <X className="text-slate-500" size={16} />
            </button>
          </div>
          <div className="px-6 pb-6 flex flex-col">
            <div className="flex flex-col space-y-1.5 text-center">
              <h2 className="text-xl font-semibold">Start using REA today</h2>
            </div>
            <form>
              <div className="space-y-2">
                <label
                  className="text-sm font-medium leading-none"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="flex w-full border border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-10 px-4 py-2 rounded-md"
                  placeholder="Joseph"
                  required
                  name="firstName"
                  id="firstName"
                  aria-describedby="firstName-description firstName-message"
                  aria-invalid="false"
                />
              </div>
              <div className="space-y-2">
                <label
                  className="text-sm font-medium leading-none"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="flex w-full border border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-10 px-4 py-2 rounded-md"
                  placeholder="name@company.com"
                  required
                  name="email"
                  id="email"
                  aria-describedby="email-description email-message"
                  aria-invalid="false"
                />
              </div>
              <div className="space-y-2">
                <label
                  className="text-sm font-medium leading-none"
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <input
                  className="flex w-full border border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-10 px-4 py-2 rounded-md"
                  placeholder="+1 (415) 555-0123"
                  required
                  name="phoneNumber"
                  id="phoneNumber"
                  aria-describedby="phoneNumber-description phoneNumber-message"
                  aria-invalid="false"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="occupation"
                  className="text-sm font-medium leading-none"
                >
                  Describe your situation
                </label>
                <select
                  tabindex="-1"
                  data-gtm-form-interact-field-id="0"
                  className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                >
                  <option value="Individual">Individual</option>
                  <option value="Familly Office">Familly Office</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <button
                type="submit"
                className="mt-4 bg-black text-white px-4 py-2 font-semibold rounded-lg"
              >
                Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default SignUpModal;
