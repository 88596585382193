import React from "react";

const SearchShortcut = ({ text }) => {
  const handleInformationQuery = () => {
    // this is where we ask ChatGPT a question
    return null;
  };

  return (
    <div className="flex">
      <button
        className="flex items-center inline-block space-x-2 rounded-full bg-black px-2 py-2 mr-2 shadow-lg hover:outline"
        onClick={handleInformationQuery()}
      >
        <img src="assets/ai-stars.png" alt="ai-starts" className="h-4 w-4" />
        <span className="text-white text-sm font-semibold">{text}</span>
      </button>
    </div>
  );
};

export default SearchShortcut;
