import React from "react";

const LoadingDots = () => {
  return (
    <div className="w-6 h-6 flex flex-col items-center justify-center">
      <div className="flex space-x-1">
        <span
          className="w-1 h-1 bg-white rounded-full transition-transform animate-bounce"
          style={{
            animation: "dotBounce 1s infinite",
            animationDelay: "0s",
          }}
        />
        <span
          className="w-1 h-1 bg-white rounded-full transition-transform animate-bounce"
          style={{
            animation: "dotBounce 1s infinite",
            animationDelay: "0.2s",
          }}
        />
        <span
          className="w-1 h-1 bg-white rounded-full transition-transform animate-bounce"
          style={{
            animation: "dotBounce 1s infinite",
            animationDelay: "0.4s",
          }}
        />
      </div>

      <style jsx>{`
        @keyframes dotBounce {
          0%,
          100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-6px);
          }
        }
      `}</style>
    </div>
  );
};

export default LoadingDots;
