import React, { useState, useEffect } from "react";
import PictureModal from "./PictureModal";

const PhotoCarrousel = ({ property }) => {
  const [images, setImages] = useState([]);
  const [propertyModalOpen, setPropertyModalOpen] = useState(false);

  useEffect(() => {
    if (property && property.originalPhotosArray) {



      try {
        // const photos = JSON.parse(property.originalPhotos);

        // const imageUrls = photos
        //   .map((photo) => {
        //     const firstWebp = photo.mixedSources?.webp[0];
        //     return firstWebp ? firstWebp.url : null;
        //   })
        //   .filter(Boolean);

        const imageUrls = property.originalPhotosArray;


        setImages(imageUrls);
      } catch (error) {
        console.error("Failed to parse originalPhotos:", error);
      }
    }
  }, [property]);

  const togglePictureModal = () => {
    setPropertyModalOpen(!propertyModalOpen);
  };

  return (
    <div className="flex flex-col w-2/3 mt-3">
      <div className="relative w-full">
        {images.length > 0 ? (
          <div>
            <img
              src={images[0]}
              alt={`Property image number 1`}
              className="w-full h-72 object-cover rounded-lg"
            />
            <button
              className="absolute bottom-3 bg-white rounded-full border border-slate-100 px-2 py-1 font-semibold left-1/2 transform -translate-x-1/2 shadow-xl hover:py-2 hover:px-3"
              onClick={togglePictureModal}
            >
              Photos
            </button>
            <PictureModal
              isOpen={propertyModalOpen}
              onClose={togglePictureModal}
              propertyPictures={images}
            />
          </div>
        ) : (
          <p>No images available</p>
        )}
      </div>
      <div className="flex align-top mt-3 h-72">
        <div className="flex w-1/2 mr-3">
          {images.length > 1 ? (
            <div className="relative w-full h-full">
              <img
                src={images[1]}
                alt={`Property image number 2`}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ) : (
            <p>No more images available</p>
          )}
        </div>
        <div className="flex w-1/2">
          {images.length > 2 ? (
            <div className="relative w-full h-full">
              <img
                src={images[2]}
                alt={`Property image number 3`}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PhotoCarrousel;
