import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";

const SideBarDropDown = ({ DropDownName }) => {
  const navigate = useNavigate();

  const toggleNewSearch = () => {
    navigate("/search");
  };

  return (
    <div className="mb-4 border-b border-1 border-slate-200 cursor-pointer">
      <div className="p-3">
        <div
          className="flex px-4 py-2 items-center justify-between rounded-xl hover:bg-gray-300 group"
          onClick={DropDownName === "New search" ? toggleNewSearch : null}
        >
          <p className="text-lg">{DropDownName}</p>
          <div className="invisible group-hover:visible">
            {DropDownName === "New search" ? (
              <Plus size={20} />
            ) : (
              <ChevronDown size={20} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarDropDown;
