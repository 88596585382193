// CopilotMessage.jsx
import React, { useState, useEffect } from 'react';
import { Bot, User } from 'lucide-react';

export const CopilotMessage = ({ message, isUser, isTyping, bottomRef }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (!message) {
      setDisplayedText('');
      return;
    }

    // For immediate display (welcome/user messages), skip typing effect
    if (!isTyping || isUser) {
      setDisplayedText(message);
      return;
    }

    // Faster typing effect for assistant messages
    let currentIndex = 0;
    const letterInterval = setInterval(() => {
      if (currentIndex < message.length) {
        setDisplayedText(message.substring(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(letterInterval);
      }
    }, 30); // Typing speed of 30ms per character

    return () => clearInterval(letterInterval);
  }, [message, isTyping, isUser]);

  // Scroll to bottomRef whenever displayedText updates
  useEffect(() => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [displayedText, bottomRef]);

  // Convert Markdown-like text to HTML tags
  const convertedText = displayedText
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/\*(.*?)\*/g, '<em>$1</em>');

  return (
    <div className={`flex items-start gap-2 mb-4 ${isUser ? 'flex-row-reverse' : ''}`}>
      <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center
        ${isUser ? 'bg-black' : 'bg-blue-600'}`}>
        {isUser ? (
          <User className="w-5 h-5 text-white" />
        ) : (
          <Bot className="w-5 h-5 text-white" />
        )}
      </div>
      <div className={`flex-1 max-w-[80%] py-3 px-4 min-h-[48px] rounded-2xl ${
        isUser ? 'bg-gray-100 rounded-tr-none' : 'bg-blue-50 rounded-tl-none'
      }`}>
        <p
          className="text-sm text-gray-800 whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: convertedText }}
        />
        {isTyping && !convertedText && (
          <span className="ml-1 flex space-x-1">
            <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
            <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
            <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
          </span>
        )}
      </div>

      <style jsx>{`
        @keyframes bubble {
          0%, 80%, 100% { transform: scale(1); opacity: 0.3; }
          40% { transform: scale(1.5); opacity: 1; }
        }
        .dot {
          animation: bubble 1.4s infinite ease-in-out;
        }
        .dot:nth-child(1) { animation-delay: 0s; }
        .dot:nth-child(2) { animation-delay: 0.2s; }
        .dot:nth-child(3) { animation-delay: 0.4s; }
      `}</style>
    </div>
  );
};







// CopilotMessage.jsx
// import React, { useState, useEffect } from 'react';
// import { Bot, User } from 'lucide-react';

// export const CopilotMessage = ({ message, isUser, isTyping, bottomRef }) => {
//   const [displayedText, setDisplayedText] = useState('');
  
//   useEffect(() => {
//     if (!message) {
//       setDisplayedText('');
//       return;
//     }

//     // For immediate display (welcome/user messages), skip typing effect
//     if (!isTyping || isUser) {
//       setDisplayedText(message);
//       return;
//     }

//     // Faster typing effect for assistant messages
//     let currentIndex = 0;
//     const letterInterval = setInterval(() => {
//       if (currentIndex < message.length) {
//         setDisplayedText(message.substring(0, currentIndex + 1));
//         currentIndex++;
//       } else {
//         clearInterval(letterInterval);
//       }
//     }, 30); // Typing speed of 30ms per character

//     return () => clearInterval(letterInterval);
//   }, [message, isTyping, isUser]);

//   // Scroll to bottomRef whenever displayedText updates
//   useEffect(() => {
//     if (bottomRef && bottomRef.current) {
//       bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
//     }
//   }, [displayedText, bottomRef]);


//   const convertedText = displayedText
//   .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
//   .replace(/\*(.*?)\*/g, '<em>$1</em>');

//   return (
//     <div className={`flex items-start gap-2 mb-4 ${isUser ? 'flex-row-reverse' : ''}`}>
//       <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center 
//         ${isUser ? 'bg-black' : 'bg-blue-600'}`}>
//         {isUser ? (
//           <User className="w-5 h-5 text-white" />
//         ) : (
//           <Bot className="w-5 h-5 text-white" />
//         )}
//       </div>
//       <div className={`flex-1 max-w-[80%] p-4 rounded-2xl ${
//         isUser ? 'bg-gray-100 rounded-tr-none' : 'bg-blue-50 rounded-tl-none'
//       }`}>
//         <p className="text-sm text-gray-800 whitespace-pre-wrap">
//           {convertedText || (isTyping && (
//             <span className="ml-1 flex space-x-1">
//               <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
//               <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
//               <span className="dot w-2 h-2 bg-gray-400 rounded-full"></span>
//             </span>
//           ))}
//         </p>
//       </div>

//       <style jsx>{`
//         @keyframes bubble {
//           0%, 80%, 100% { transform: scale(1); opacity: 0.3; }
//           40% { transform: scale(1.5); opacity: 1; }
//         }
//         .dot {
//           animation: bubble 1.4s infinite ease-in-out;
//         }
//         .dot:nth-child(1) { animation-delay: 0s; }
//         .dot:nth-child(2) { animation-delay: 0.2s; }
//         .dot:nth-child(3) { animation-delay: 0.4s; }
//       `}</style>
//     </div>
//   );
// };











