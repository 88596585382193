import React from "react";
import PropertyImages from "./PropertyImages";
import InfoBox from "./InfoBox";

const PropertyCard = ({ property, togglePropertyModal }) => {
  // Receives individual property data (content) from GridList

  return (
    <div
      className="flex flex-col w-full cursor-pointer"
      onClick={() => togglePropertyModal(property)}
    >
      <PropertyImages property={property} />
      {/* Pass property images to PropertyImages */}
      <InfoBox property={property} /> {/* Pass property data to InfoBox */}
    </div>
  );
};

export default PropertyCard;
