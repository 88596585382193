import React from "react";
import { ChevronDown, MessagesSquare } from "lucide-react";

const SearchName = () => {
  return (
    <div className="flex gap-1">
      <MessagesSquare />
      <p className="text-base font-medium">San Francisco</p>
      <ChevronDown />
    </div>
  );
};

export default SearchName;
