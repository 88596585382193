import React from "react";
import { Download } from "lucide-react";

import SearchName from "../TopBarComponents/SearchName";
import ActionButton from "../../GeneralComponents/ActionButton";

const TopBarSearchScreen = () => {
  return (
    <div className="flex items-center justify-between max-w-full mb-3">
      <SearchName />
      <ActionButton Text="Share" Icon={Download} />
    </div>
  );
};

export default TopBarSearchScreen;
