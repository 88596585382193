import React, { useState } from "react";
import ActionButton from "../GeneralComponents/ActionButton";

const DescriptionSection = ({ description }) => {
  const [descriptionVisible, setDescriptionVisible] = useState(false);

  const toggleDescription = () => {
    setDescriptionVisible(!descriptionVisible);
  };

  return (
    <div className="flex flex-col align-top w-full hover:bg-gray-50 justify-start border border-x-0 border-black border-t-0">
      <div className="my-5">
        <h2 className="font-semibold text-2xl mb-2">Description</h2>
        {description.length > 300 ? (
          !descriptionVisible ? (
            <div>
              <p className="text-sm mb-3">{description.slice(0, 300)}...</p>
              <ActionButton
                Text="Read full Description"
                onClick={toggleDescription}
              />
            </div>
          ) : (
            <div>
              <p className="text-sm mb-3">{description}</p>
              <ActionButton
                Text="Hide full Description"
                onClick={toggleDescription}
              />
            </div>
          )
        ) : (
          <p className="text-sm">{description}</p>
        )}
      </div>
    </div>
  );
};

export default DescriptionSection;
