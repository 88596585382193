import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const ModalMap = ({ location }) => {
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(null);
  const [center, setCenter] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  };

  const mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: true,
  };

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (location && map) {
      const coordinates = {
        lat: Number(location.mlat),
        lng: Number(location.mlong),
      };
      setCenter(coordinates);
      setPosition(coordinates);
    }
  }, [location, map]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={center || { lat: 37.7749, lng: -122.4194 }}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Only render Marker if map is loaded and position is set */}
        {position && <Marker position={position} />}
      </GoogleMap>
    </div>
  );
};

export default ModalMap;
