import React from "react";
import { ArrowLeft } from "lucide-react";

const ModalHeaderClose = ({ onClose }) => {
  return (
    <div
      className="flex items-center text-base cursor-pointer"
      onClick={onClose}
    >
      <ArrowLeft size={16} className="mr-2 stroke-[2px]" />
      <p className="mr-5 font-medium">Search</p>
    </div>
  );
};

export default ModalHeaderClose;
