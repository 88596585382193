import React from "react";

const LogoImage = () => {
  return (
    <div className="w-full flex items-center justify-start">
      <img src="/assets/ReaLogo.svg" alt="ReaLogo" />
    </div>
  );
};

export default LogoImage;
