import React from "react";

const ModalNavigationHeader = ({
  scrollToSection,
  overviewRef,
  picturesRef,
  floorplanRef,
  propertyInfoRef,
  locationRef,
  ratingRef,
}) => {
  const navItems = [
    { ref: overviewRef, label: "Overview" },
    { ref: picturesRef, label: "Pictures" },
    { ref: floorplanRef, label: "Floor plan" },
    { ref: propertyInfoRef, label: "Property info" },
    { ref: locationRef, label: "Location" },
    { ref: ratingRef, label: "Rating" },
  ];

  return (
    <nav className="w-full">
      <div className="max-w-7xl mx-auto px-4">
        <ul className="flex items-center space-x-8">
          {navItems.map((item, index) => (
            <li key={index}>
              <button
                onClick={() => scrollToSection(item.ref)}
                className={`inline-block py-4 text-gray-600 hover:text-gray-900 ${
                  index === 0 ? "border-b-2 border-black text-black" : ""
                }`}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default ModalNavigationHeader;
