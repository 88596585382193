import React from "react";
import { Phone, Mail, SquareArrowOutUpRight, MapPin } from "lucide-react";
import AgentLogo from "./AgentLogo";

const AgentInfo = ({ agentDetails = {} }) => {
  return (
    <div className="flex border-black border border-y-1 border-x-0">
      <div className="flex flex-col border border-black border-l-0 border-r-1 border-y-0 p-1 w-1/5">
        <span className="text-sm font-medium mb-0.5">Listed by</span>
        <AgentLogo agent={agentDetails.agentName} />
      </div>
      <div className="flex flex-col border border-black border-l-0 border-r-1 border-y-0 p-1 w-1/3 ">
        <span className="text-sm font-medium mb-0.5">Contact</span>
        <div className="flex space-x-3">
          {agentDetails?.agentPhoneNumber != null ? (
            <div className="flex items-center my-1">
              <Phone size={14} className="mr-1" />
              <span className="text-sm">{agentDetails.agentPhoneNumber}</span>
            </div>
          ) : null}
          {agentDetails?.agentEmail != null ? (
            <div className="flex items-center my-1">
              <Mail size={14} className="mr-1" />
              <a href={agentDetails.agentEmail} className="text-sm">
                {agentDetails.agentEmail}
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <div className="p-1 flex flex-col align-middle justify-items-start">
        <div className="flex items-center">
          <SquareArrowOutUpRight className="mr-1" size={14} />
          <a href="https://www.rentbt.com/" className="text-sm">
            {agentDetails.postingGroupName}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
