import React, { useState } from "react";
import ActionButton from "../GeneralComponents/ActionButton";
import { Fence, AirVent } from "lucide-react";

const PlaceCaracteristics = () => {
  const [viewCaracteristics, setViewCaracteristics] = useState(false);

  // We should present the caracteristics ourself based on NLP analysis
  const caracteristics = [
    { name: "Garden", icon: Fence },
    { name: "Air conditioning", icon: AirVent },
  ];

  return (
    <div className="flex flex-col align-top w-full hover:bg-gray-50 justify-start border border-x-0 border-black border-t-0">
      <div className="my-5">
        <h2 className="font-semibold text-2xl mb-2">
          What the place has to offer
        </h2>
        {caracteristics.map((caract) => (
          <div className="flex items-center">
            <caract.icon size={14} className="mr-1" />
            <span>{caract.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaceCaracteristics;
