import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/SearchPageComponents/MainComponents/SideBar";
import TopBarSearchScreen from "../components/SearchPageComponents/MainComponents/TopBarSearchScreen";
import SearchResults from "../components/SearchPageComponents/MainComponents/SearchResults";
import SearchBar from "../components/SearchBar";
import Modal from "../components/SearchPageComponents/MainComponents/Modal";
import LoadingAnimation from "../components/SearchPageComponents/LoadingAnimation";
import LoginModal from "../components/GeneralComponents/LoginModal";
import SignUpModal from "../components/GeneralComponents/SignUpModal";

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // State management
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [properties, setProperties] = useState([]);
  const [criteria, setCriteria] = useState({});
  const [currentQuery, setCurrentQuery] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);

  const handleSignUpModal = () => {
    setSignUp(!signUp);
  };

  const handleLoginModal = () => {
    setLogin(!login);
  };

  // Clear state on reload and set initial state
  useEffect(() => {
    const handleReload = () => {
      // Clear all state
      setProperties([]);
      setCriteria({});
      setCurrentQuery("");
      // Force clear router state
      navigate("/search", { replace: true, state: null });
    };

    // Handle page reload
    if (performance.navigation.type === 1) {
      handleReload();
    } else if (location.state) {
      // Set initial state from navigation
      setProperties(location.state.properties || []);
      setCriteria(location.state.criteria || {});
      setCurrentQuery(location.state.query || "");
    }
  }, [navigate]);

  // Modal handlers
  const togglePropertyModal = (property) => {
    setIsModalOpen(!isModalOpen);
    setSelectedProperty(property);
  };

  // Update criteria and properties when new search is performed
  const handleUpdateCriteria = async (
    newProperties,
    updatedCriteria,
    newQuery
  ) => {
    setProperties(newProperties);
    setCriteria(updatedCriteria);
    setCurrentQuery(newQuery);
  };

  return (
    <Sidebar
      handleLoginModal={handleLoginModal}
      handleSignUpModal={handleSignUpModal}
    >
      <div className="relative h-screen flex flex-col p-2 overflow-hidden">
        {/* Main content area */}
        <TopBarSearchScreen />
        <div className="flex-1 max-w-full min-h-0 overflow-auto">
          {loadingState ? <LoadingAnimation /> : null}
          <SearchResults
            properties={properties}
            togglePropertyModal={togglePropertyModal}
            query={currentQuery}
            criteria={criteria}
          />
        </div>

        {/* Search bar at bottom */}
        <div className="absolute bottom-0 w-full flex items-center justify-center p-4">
          <div className="w-full max-w-[50%]">
            <SearchBar
              currentCriteria={criteria}
              onUpdateCriteria={handleUpdateCriteria}
              setLoadingState={setLoadingState}
            />
          </div>
        </div>
      </div>

      {/* Property Modal */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={togglePropertyModal}
          propertyInfo={selectedProperty}
        />
      )}

      <LoginModal isOpen={login} onClose={handleLoginModal} />
      <SignUpModal isOpen={signUp} onClose={handleSignUpModal} />
    </Sidebar>
  );
};

export default SearchPage;
