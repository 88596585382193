import React from "react";
import { ThumbsDown, Bath, BedDouble } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const InfoBox = ({ property = {} }) => {
  // Default property to an empty object to avoid errors

  const {
    price = 0,
    address = "{}",
    bathrooms = "-",
    bedrooms = "-",
  } = property; // Extract fields with defaults

  // Convert price string to a number and format with commas (no decimals)
  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(price));

  // Parse the address JSON (since it's stored as a JSON string)
  let parsedAddress = {};
  try {
    parsedAddress = JSON.parse(address);
  } catch (error) {
    parsedAddress = { streetAddress: address }; // Fallback to the string if parsing fails
  }

  const fullAddress = `${parsedAddress.streetAddress || "Unknown Address"}, ${
    parsedAddress.city || ""
  }, ${parsedAddress.state || ""} ${parsedAddress.zipcode || ""}`;

  return (
    <div className="p-2 bg-white rounded-b-lg flex flex-col h-2/5 justify-between">
      <div className="flex flex-col">
        <div className="flex items-center justify-between space-x-2">
          <span className="text-base font-medium">{formattedPrice}</span>{" "}
          {/* Display formatted price */}
          <div className="flex items-center space-x-1">
            <div className="flex items-center space-x-1">
              <span>{bathrooms}</span>
              <Bath size={15} />
            </div>
            <div className="flex items-center space-x-1">
              <span>{bedrooms}</span>
              <BedDouble size={15} />
            </div>
          </div>
        </div>

        <div>
          <span className="text-xs">{fullAddress}</span>
        </div>
      </div>

      <div className="flex items-center mt-3">
        <div>
          <ActionButton Text="" Icon={ThumbsDown} />
        </div>
        <div className="ml-1">
          <ActionButton Text="Contact Agent" />
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
