import React from "react";
import { ArrowLeft } from "lucide-react";

const PictureModal = ({ isOpen, onClose, propertyPictures = [] }) => {
  return (
    <div
      className={`fixed inset-0 z-50 transition-transform ${
        isOpen ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed right-0 h-full w-[85vw] bg-white shadow-2xl transition-transform duration-200 rounded-l-lg border border-gray-100">
        <div className="h-full flex flex-col">
          <div className="px-8 py-2 overflow-y-auto">
            <div
              className="flex items-center text-base cursor-pointer"
              onClick={onClose}
            >
              <ArrowLeft size={16} className="mr-2 stroke-[2px]" />
              <p className="mr-5 font-medium">Go back to property overview</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
              {propertyPictures.map((photo) => (
                <div className="relative aspect-square rounded-lg overflow-hidden bg-gray-100">
                  <img
                    key={photo.id}
                    src={photo}
                    className="object-cover w-full h-full hover:scale-105 transition-transform duration-200"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PictureModal;
