import React from "react";
import { Bookmark, Clock, Download } from "lucide-react";
import ActionButton from "../GeneralComponents/ActionButton";

const TimeAndSocial = ({ daysPosted }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Clock size={12} className="text-gray-700 mr-2" />
        <p className="text-xs text-gray-700">{daysPosted} Day ago</p>
      </div>
      <div className="flex items-center">
        <div>
          <ActionButton Text="Save" Icon={Bookmark} />
        </div>
        <div className="ml-1">
          <ActionButton Text="Share" Icon={Download} />
        </div>
      </div>
    </div>
  );
};

export default TimeAndSocial;
