import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/HomePageComponents/Header";
import SkylineImage from "../components/HomePageComponents/SkylineImage";
import Hero from "../components/HomePageComponents/Hero";
import SignUpModal from "../components/GeneralComponents/SignUpModal";
import LoginModal from "../components/GeneralComponents/LoginModal";

const HomePage = () => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  const handleSignUpModal = () => {
    setSignUp(!signUp);
  };

  const handleLoginModal = () => {
    setLogin(!login);
  };

  // Clear properties on page reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      setProperties([]);
      // Force clear state in router navigation
      navigate("/search", { replace: true, state: null });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [navigate]);

  return (
    <div className="flex flex-col min-h-screen items-center bg-white">
      <Header
        handleSignUpModal={handleSignUpModal}
        handleLoginModal={handleLoginModal}
      />
      <Hero setProperties={setProperties} />
      <SkylineImage />
      <SignUpModal isOpen={signUp} onClose={handleSignUpModal} />
      <LoginModal isOpen={login} onClose={handleLoginModal} />
    </div>
  );
};

export default HomePage;
