import React from "react";

const HiddenNotice = ({ handleResultHidden, hidden }) => {
  return (
    <div className="px-3 py-1 inline-block w-auto items-center">
      <button
        onClick={handleResultHidden}
        className="text-black text-xs hover:underline inline-block w-auto"
      >
        {hidden === true ? "Show" : "Hide"}
      </button>
    </div>
  );
};

export default HiddenNotice;
