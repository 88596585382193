import React from "react";
import SideBarDropDown from "./SideBarDropdown";

const SideBarMenu = () => {
  return (
    <div>
      <SideBarDropDown DropDownName="New search" />
      <SideBarDropDown DropDownName="Saved properties" />
      <SideBarDropDown DropDownName="History" />
    </div>
  );
};

export default SideBarMenu;
