import React from "react";
import { useNavigate } from "react-router-dom";
import LogoImage from "../SideBarComponents/LogoImage";
import SignUpModal from "../GeneralComponents/SignUpModal";

const Header = ({ handleSignUpModal, handleLoginModal }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    // Ensure properties are passed to SearchPage when navigating
    navigate("/");
  };

  return (
    <div className="relative sticky top-0 flex w-full items-center border-b border-gray-200 py-4">
      <div className="flex items-center justify-between w-full container mx-auto px-4">
        <div className="flex items-center space-x-6">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleRedirect}
          >
            <LogoImage />
            <span className="text-xl font-semibold ml-2">REA</span>
          </div>
          <div className="flex hidden md:flex items-center space-x-8">
            <a href="#" className="text-slate-900 hover:text-gray-600">
              About
            </a>
            <a href="#" className="text-slate-900 hover:text-gray-600">
              Post a listing
            </a>
          </div>
        </div>
        <div className="flex items-center space-x-4 cursor-pointer">
          <a
            href="#"
            className="text-slate-900 hover:text-gray-600"
            onClick={handleLoginModal}
          >
            Log In
          </a>
          <button
            className="rounded-full inline-flex items-center text-white font-semibold px-4 py-2 hover:bg-gray-600 bg-black transition-colors"
            onClick={handleSignUpModal}
          >
            Create an account
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
