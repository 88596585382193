// ChatContainer.jsx
import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CopilotMessage } from './CopilotMessage';

export const ChatContainer = ({ messages }) => {
  const bottomRef = useRef(null);

  return (
    <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-200px)]">
      <AnimatePresence>
        {messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CopilotMessage
              message={msg.content}
              isUser={msg.role === 'user'}
              isTyping={msg.isTyping}
              bottomRef={bottomRef} // Pass the bottomRef to CopilotMessage
            />
          </motion.div>
        ))}
      </AnimatePresence>
      <div ref={bottomRef} className="h-0" />
    </div>
  );
};



