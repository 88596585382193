// CopilotBar.jsx
import React, { useState, useRef } from "react";
import { ArrowUp, Loader, Bot } from "lucide-react";
import axios from "axios";

export const CopilotBar = ({ propertyInfo, onNewMessage, chatHistory }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const abortController = useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (!inputValue.trim() || !propertyInfo) return;

    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();

    const userMessage = {
      role: "user",
      content: inputValue,
    };
    
    setInputValue("");
    setLoading(true);
    
    onNewMessage(userMessage);

    const assistantMessage = {
      role: "assistant",
      content: "",
      isTyping: true
    };
    
    onNewMessage(assistantMessage);

    try {
      const response = await axios.post(
        "https://copilotsearch-uvlx53yrmq-uc.a.run.app",
        {
          query: inputValue,
          propertyInfo: propertyInfo,
          chatHistory: chatHistory
        },
        {
          timeout: 30000,
          signal: abortController.current.signal,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      onNewMessage({
        role: "assistant",
        content: response.data.message,
        isTyping: false,
        replaceTyping: true
      });
    } catch (error) {
      console.error("Error processing request:", error);
      
      let errorMessage = "I encountered an error. Please try again.";
      
      if (error.code === "ECONNABORTED" || error.name === "AbortError") {
        errorMessage = "The request took too long. Please try a shorter or simpler question.";
      } else if (error.response?.status === 429) {
        errorMessage = "I'm receiving too many requests right now. Please try again in a moment.";
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }

      onNewMessage({
        role: "assistant",
        content: errorMessage,
        replaceTyping: true
      });
    } finally {
      setLoading(false);
      abortController.current = null;
    }
  };

  return (
    <div className="relative">
      <div className="relative rounded-full border border-gray-200 bg-white shadow-lg">
        <button
          className="absolute top-1/2 left-3 transform -translate-y-1/2 rounded-full p-2"
          disabled={loading}
        >
          {loading ? (
            <Loader className="w-5 h-5 text-blue-600 animate-spin" />
          ) : (
            <Bot className="w-5 h-5 text-gray-400" />
          )}
        </button>
        <input
          type="text"
          placeholder="Ask me anything about this property..."
          className={`w-full pl-12 pr-12 py-3 rounded-full bg-transparent text-sm focus:outline-none
            ${loading ? 'cursor-not-allowed bg-gray-50' : ''}`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyPress}
          ref={inputRef}
          disabled={loading}
        />
        <button
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 rounded-full p-2 
            ${!inputValue.trim() || loading ? 'bg-gray-100 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
          disabled={!inputValue.trim() || loading}
          onClick={handleSearch}
        >
          <ArrowUp className={`w-4 h-4 ${!inputValue.trim() || loading ? 'text-gray-400' : 'text-white'}`} />
        </button>
      </div>
    </div>
  );
};


