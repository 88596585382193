import React, { useState } from "react";
import ModalMap from "./ModalMap";

const LocalInfo = ({ location }) => {
  return (
    <div className="flex flex-col align-top w-full hover:bg-gray-50 justify-start border border-x-0 border-black border-t-0">
      <div className="my-5 w-full">
        <h2 className="font-semibold text-2xl mb-2">Local area information</h2>
        <div className="relative flex-grow h-[400px] bg-gray-100">
          <ModalMap location={location} />
        </div>
      </div>
    </div>
  );
};

export default LocalInfo;
