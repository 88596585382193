import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PanelLeft, PanelLeftClose } from "lucide-react";

import LogoImage from "../../SideBarComponents/LogoImage";
import SideBarMenu from "../../SideBarComponents/SideBarMenu";
import LoginButtonMargin from "../../SideBarComponents/LoginButtonMargin";

const Sidebar = ({ children, handleLoginModal, handleSignUpModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    // Ensure properties are passed to SearchPage when navigating
    navigate("/");
  };

  const handleMouseEnter = useCallback(() => {
    if (!isSticky) {
      setIsHovering(true);
      setIsOpen(true);
    }
  }, [isSticky]);

  const handleMouseLeave = useCallback(() => {
    if (!isSticky) {
      setIsHovering(false);
      setIsOpen(false);
    }
  }, [isSticky]);

  const toggleSticky = useCallback(() => {
    setIsSticky(!isSticky);
    setIsOpen(!isSticky);
  }, [isSticky]);

  return (
    <div className="flex h-screen">
      <div
        className={`sidebar fixed left-0 top-0 h-full bg-gray-50 border-r border-1 border-slate-200 transition-all duration-100 ease-in-out z-10 ${
          isOpen ? "w-64" : "w-16"
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex justify-between items-center p-3">
          <div className="cursor-pointer" onClick={handleRedirect}>
            <LogoImage />
          </div>
          <button
            onClick={toggleSticky}
            className={`absolute right-2 p-2 items-center text-gray-400 hover:text-gray-950 hover:bg-gray-300 hover:rounded-lg focus:outline-none ${
              isOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            {isSticky ? <PanelLeftClose size={20} /> : <PanelLeft size={20} />}
          </button>
        </div>
        <div
          className={`h-full overflow-hidden ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          <SideBarMenu />
          <LoginButtonMargin
            handleLoginModal={handleLoginModal}
            handleSignUpModal={handleSignUpModal}
          />
        </div>
      </div>
      <div
        className={`flex-1 transition-all duration-300 ease-in-out ${
          isOpen ? "ml-64" : "ml-16"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
