import React, { useState, useRef } from "react";
import { ArrowUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import LoadingDots from "./SearchPageComponents/LoadingDots";

const SearchBarHome = ({ setProperties }) => {
  const [inputValue, setInputValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const fetchFullPropertyDetails = async (propertyIds) => {
    try {
      console.log("Sending property IDs:", propertyIds);

      const response = await fetch(
        "https://analyzeinput2-uvlx53yrmq-uc.a.run.app/properties",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ids: propertyIds
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const fullProperties = await response.json();
      return fullProperties;
    } catch (error) {
      console.error("Error fetching full property details:", error);
      throw error;
    }
  };

  const handleButtonClick = async () => {
    if (!inputValue.trim()) {
      alert("Please enter a search query.");
      return;
    }

    inputRef.current.blur();
    setIsLoading(true);

    let searchResult = null; // Declare searchResult at the top of the function

    try {
      // Step 1: Initial search
      const searchResponse = await fetch(
        "https://analyzeinput2-uvlx53yrmq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ input: inputValue }),
        }
      );

      if (!searchResponse.ok) {
        throw new Error(`HTTP error! status: ${searchResponse.status}`);
      }

      searchResult = await searchResponse.json(); // Assign to our declared variable
      console.log("Initial search results:", searchResult);

      if (!searchResult.properties || !Array.isArray(searchResult.properties)) {
        throw new Error("Invalid response format - missing properties array");
      }

      console.log("First property object:", searchResult.properties[0]);

      const propertyIds = searchResult.properties.map(prop => prop.id || prop.propertyId || prop.zpid);
      console.log("Extracted property IDs:", propertyIds);

      if (propertyIds.length === 0) {
        throw new Error("No property IDs found in search results");
      }

      try {
        // Step 3: Fetch full details
        const fullPropertyDetails = await fetchFullPropertyDetails(propertyIds);
        console.log("Full property details:", fullPropertyDetails);

        // Use full details if available
        setProperties(fullPropertyDetails);
        navigate("/search", {
          state: {
            properties: fullPropertyDetails,
            query: inputValue,
            criteria: searchResult.criteria,
          },
        });
      } catch (detailsError) {
        console.error("Error fetching full details, falling back to search results:", detailsError);
        // Fallback to using initial search results
        setProperties(searchResult.properties);
        navigate("/search", {
          state: {
            properties: searchResult.properties,
            query: inputValue,
            criteria: searchResult.criteria,
          },
        });
      }
    } catch (error) {
      console.error("Error during search:", error);
      
      // If we have initial search results, use them
      if (searchResult?.properties) {
        setProperties(searchResult.properties);
        navigate("/search", {
          state: {
            properties: searchResult.properties,
            query: inputValue,
            criteria: searchResult.criteria,
          },
        });
      } else {
        alert("An error occurred while searching. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleButtonClick();
    }
  };

  const isButtonDisabled = !inputValue;

  return (
    <div className="relative border rounded-full border-black w-full max-w-lg">
      <input
        type="text"
        placeholder="What type of property are you looking for"
        className="w-full px-4 py-3 rounded-full bg-gray-50 text-base"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyPress}
        ref={inputRef}
      />
      <div
        className="absolute right-1 top-1/2 transform -translate-y-1/2"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isButtonDisabled && isHovered && (
          <div className="absolute right-0 -translate-y-full transform mb-2 bg-slate-900 text-white text-xs rounded p-1">
            Property description is empty
          </div>
        )}
        <button
          onClick={handleButtonClick}
          className={`rounded-full px-2 py-2 ${
            isButtonDisabled ? "bg-neutral-200 cursor-not-allowed" : "bg-black"
          }`}
          disabled={isButtonDisabled}
        >
          {isLoading ? (
            <LoadingDots />
          ) : (
            <ArrowUp
              className={`transition-colors duration-200 ${
                isButtonDisabled ? "text-zinc-500" : "text-white"
              }`}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default SearchBarHome;








