import React from "react";

const AgentLogo = ({ agent }) => {
  return (
    <div className="flex items-center">
      <img src={"assets/agentLogo.png"} alt={`Agent's logo`} />
      <span className="ml-2 text-black text-base">{agent}</span>
    </div>
  );
};

export default AgentLogo;
