import React from "react";

const SkylineImage = () => {
  return (
    <div className="absolute bottom-0 w-full flex items-center justify-center">
      <img src="/assets/sfSkyLine.svg" className="" alt="Skyline" />
    </div>
  );
};

export default SkylineImage;
