import React from "react";

const LoadingAnimation = () => {
  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black opacity-50" />
      <div className="fixed inset-0">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h2 className="font-medium text-xl text-white">REA is searching</h2>
          <div className="flex space-x-2 mt-5">
            <span
              className="w-3 h-3 bg-white rounded-full transition-transform animate-bounce"
              style={{
                animation: "dotBounce 1s infinite",
                animationDelay: "0s",
              }}
            />
            <span
              className="w-3 h-3 bg-white rounded-full transition-transform animate-bounce"
              style={{
                animation: "dotBounce 1s infinite",
                animationDelay: "0.2s",
              }}
            />
            <span
              className="w-3 h-3 bg-white rounded-full transition-transform animate-bounce"
              style={{
                animation: "dotBounce 1s infinite",
                animationDelay: "0.4s",
              }}
            />
          </div>

          <style jsx>{`
            @keyframes dotBounce {
              0%,
              100% {
                transform: translateY(0);
              }
              50% {
                transform: translateY(-12px);
              }
            }
          `}</style>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
