import React from "react";
import Prompt from "./Prompt";
import FilterUsed from "./FiltersUsed";
import HiddenNotice from "./HiddenNotice";
import GridList from "./GridList";
import SearchResultMap from "./SearchResultMap";

const SearchResult = ({
  keywords,
  properties,
  query,
  hidden,
  togglePropertyModal,
  id,
}) => {
  return (
    <div
      id={id}
      className={`
        transition-all duration-300 ease-in-out
        ${hidden ? "opacity-70 scale-100" : "opacity-100 scale-100"}
      `}
    >
      {hidden ? (
        <div className="flex mt-2 justify-end items-center w-full container max-w-[80%]">
          <div className="">
            <div className="inline-block">
              <HiddenNotice />
            </div>
            <div className="inline-block">
              <Prompt query={query} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col mt-2"
          style={{ height: "calc(100vh - 82px)" }}
        >
          <div className="inline-block flex items-center justify-end w-full container max-w-[80%]">
            <HiddenNotice />
            <Prompt query={query} />
          </div>
          <div className="border border-slate-100 bg-neutral-50 flex flex-col p-2 my-3 rounded-lg overflow-y-auto">
            <div className="flex space-x-2 align-top mb-3">
              <img src="/assets/ReaLogo.svg" className="w-9 h-9" alt="Rea Logo" />
              <div className="flex-grow">
                <FilterUsed keywords={keywords} />
              </div>
            </div>
            <div className="flex h-full overflow-y-auto align-top">
              <GridList
                properties={properties}
                togglePropertyModal={togglePropertyModal}
              />
              <div className="flex-grow pl-3">
                <SearchResultMap
                  properties={properties}
                  isVisible={!hidden}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;












