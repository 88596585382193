import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Heart } from "lucide-react";

const PropertyImages = ({ property }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  // Extract first JPEG URLs from the originalPhotos JSON
  useEffect(() => {
    if (property && property.originalPhotosArray) {
      try {
        // Parse the originalPhotos field, which is a JSON string
        // const photos = JSON.parse(property.originalPhotos);

        // // Extract the first jpeg URL from each photo in the array
        // const imageUrls = photos.map((photo) => {
        //   const firstWebp = photo.mixedSources.webp[0];
        //   return firstWebp.url;
        // });
        const imageUrls = property.originalPhotosArray;


        property.originalPhotosArray.forEach((photo) => {
          console.log("URL:", photo);
        });

        setImages(imageUrls.slice(0, 5)); // Set the extracted image URLs into state
      } catch (error) {
        console.error("Failed to parse originalPhotos:", error);
      }
    }
  }, [property]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div
      className="relative w-full max-w-sm h-60 overflow-hidden" // Fixed size container for uniformity
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Property Image */}
      <div className="relative w-full h-full">
        {images.length > 0 ? (
          <img
            src={images[currentImageIndex]}
            alt={`Property image number ${currentImageIndex}`}
            className="w-full h-full object-cover rounded-t-lg" // Fixed size for the image
          />
        ) : (
          <p>No images available</p>
        )}
      </div>

      {/* Navigation Arrows */}
      {isHovered && images.length > 1 && (
        <>
          <button
            onClick={(e) => {
              e.stopPropagation(); // This limits the click to this button. Therefore, the card can be clickable
              previousImage();
            }}
            className="absolute left-2 top-1/2 -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:scale-110 transition-transform"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              nextImage();
            }}
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:scale-110 transition-transform"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </>
      )}

      {/* Favorite Button */}
      <button className="absolute top-2 right-2 hover:scale-110 transition-transform">
        <Heart className="w-6 h-6 text-gray-100 stroke-2" />
      </button>

      {/* Image Pagination Dots */}
      <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === currentImageIndex ? "bg-white" : "bg-white/50"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default PropertyImages;
