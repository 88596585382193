import React from "react";
import PropertyCard from "./PropertyCard";

const GridList = ({ properties, togglePropertyModal }) => {
  console.log("Properties received in GridList:", properties);

  return (
    <div className="container max-w-[50%] mx-auto flex-1 min-h-0 align-top">
      {/* Scrollable area */}
      <div className="h-full overflow-y-auto">
        {/* Grid container */}
        <div className="grid grid-cols-[repeat(auto-fit,minmax(18rem,1fr))] gap-4">
          {properties.map((property) => (
            <div
              key={property.id}
              className="align-top rounded-lg shadow-lg flex w-full min-w-56 max-w-80"
            >
              <PropertyCard
                property={property}
                togglePropertyModal={togglePropertyModal}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridList;
