import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

import Keyword from "./Keyword";

const FilterUsed = ({ keywords }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="">
      <div className="flex flex-col px-4 py-2 max-w-[45%] rounded-lg border border-slate-200 bg-white">
        <button
          onClick={toggleAccordion}
          className="flex items-center justify-between"
        >
          <span className="text-xs">Research</span>
          <ChevronDown
            size={20}
            className={`transition-transform duration-300 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>
        <div
          className={`max-h-0 overflow-hidden transition-all duration-300 ease-in-out ${
            isOpen ? "max-h-screen" : ""
          }`}
        >
          <div className="text-sm text-black mt-1">
            {keywords.map((item, index) => (
              <Keyword key={index} word={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterUsed;
